import React, { useEffect, useState } from "react";

import { ScrollPageIndex } from "../components/ScrollPageIndex";
import { HomeIndex } from "../components/HomeIndex";
import { navigate } from "@reach/router"


class Home extends React.Component {

    render() {
        return (
            <> 
                { typeof window !== 'undefined' 
                    ? window.innerWidth <= 450 ? <HomeIndex /> : <ScrollPageIndex/> 
                    : null
                }
             </>
        )
    }
}

export default Home
