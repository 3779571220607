import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image"

import Footer from "./footer"
import LatestPost from "./latest-post"
import NavigationSpacer from "./navigation-spacer"
import RevealAnimation from "./reveal-animation"
import Seo from "./seo"
import TiltPhaseSix from './TiltPhaseSix'
import dogBackground from '../img/bg3.jpg'
import dogForeground from '../img/fr3.png'
import cardImage from '../img/scrapper.png'
import {
    Animator,
    batch,
    Fade,
    FadeIn,
    MoveIn,
    MoveOut,
    ScrollContainer,
    ScrollPage,
    Sticky,
    ZoomIn,
  } from '../components/react-scroll-motion';

const options = {
    max: 1,
    perspective: 1000,
    scale: 1.2,
}


export const HomeIndex = () => {
    return(
        <>
            <Seo title="Home" />
            <section id="intro" className="container-fluid main-mh-100 mb-5 position-relative">
                <NavigationSpacer />
                <div className="container h-100">
                    <div className="row h-50 justify-content-start">
                        <div className="col-lg-3 col-md-4 col-sm-7 col-8 d-block d-md-none mb-5">
                            <div className="main-fade-in-animation-delay-1 mb-3">
                                <TiltPhaseSix
                                    options={{}}
                                    style={{
                                        background: `url(${dogBackground}) no-repeat fixed center`,
                                        backgroundSize: 'fit',
                                        height: 250,
                                        width: 300,
                                    }}
                                >
                                    <TiltPhaseSix
                                        options={options}
                                    >
                                        <img
                                            style={{
                                                height: 300,
                                                width: 280,
                                            }}          
                                            src={dogForeground} 
                                            alt="" />
                                    </TiltPhaseSix>
                                </TiltPhaseSix>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-7  d-flex justify-content-center flex-column">
                            <h1 className="main-reveal-text-short">ardit<span className="main-color">.getAbout()</span></h1>
                            <div className="main-fade-in-animation">
                                <p>I am an 19 year old programmer working at StarLabs.</p>
                                <p>In my free time, I contemplate my existential dread and code random shit.</p>
                                <a href="mailto:ardit@starlabspro.com" role="button">
                                    <input type="submit" value=".contactArdit()" name="contact" className="btn main-btn-primary" />
                                </a>
                            </div>
                            {/* <div className="main-fade-in-animation-delay-2 d-block d-md-none">
                                <LatestPost />
                            </div> */}
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-7 col-8 d-none d-md-block">
                            <div className="main-fade-in-animation-delay-1">
                                <TiltPhaseSix
                                    options={{}}
                                    style={{
                                        background: `url(${dogBackground}) no-repeat fixed center`,
                                        backgroundSize: 'fit',
                                        height: 400,
                                        width: 450,
                                    }}
                                >
                                    <TiltPhaseSix
                                        options={options}
                                    >
                                        <img src={dogForeground} alt="" />
                                    </TiltPhaseSix>
                                </TiltPhaseSix>
                            </div>
                        </div>
                        <div class="mouse_scroll main-fade-in-animation-delay-2">
                            <div class="mouse">
                                <div class="wheel"></div>
                            </div>
                            <div>
                                <span class="m_scroll_arrows unu"></span>
                                <span class="m_scroll_arrows doi"></span>
                                <span class="m_scroll_arrows trei"></span>
                            </div>
                    </div>
                    </div>
                </div>
            </section>

            <section id="skills" className="container-fluid my-5 py-5" style={{maxWidth: "720px"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                                <h1 className="main-reveal-text-short">def <span className="main-color">returnSkills(): </span></h1>
                                <p className="main-fade-in-animation-delay-1">Highly skilled in <b>HTML, SCSS JS, PHP</b>, moderately skilled in <b>Laravel, Python and Node.js</b></p>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                            <div className="row justify-content-around md-justify-content-between">
                                <div className="col-3 mx-3 main-fade-in-animation-delay-1">
                                    <a aria-label="HTML 5" target="_blank" rel="noopener noreferrer" href="https://www.w3schools.com/html/">
                                        <StaticImage width={200} height={200} className="main-skill" src="../img/html.png" alt="HTML 5" />
                                    </a>
                                </div>
                                <div className="col-3 mx-3 main-fade-in-animation-delay-2">
                                    <a aria-label="SCSS" target="_blank" rel="noopener noreferrer" href="https://sass-lang.com/">
                                        <StaticImage width={200} height={200} className="main-skill" src="../img/scss.png" alt="SCSS" />
                                    </a>
                                </div>
                                <div className="col-3 mx-3 main-fade-in-animation-delay-3">
                                    <a aria-label="PHP" target="_blank" rel="noopener noreferrer" href="https://www.php.net/">
                                        <StaticImage width={200} height={200} className="main-skill" src="../img/php.png" alt="PHP" />
                                    </a>
                                </div>
                                <div className="col-3 mx-3 main-fade-in-animation-delay-4">
                                    <a aria-label="Laravel" target="_blank" rel="noopener noreferrer" href="https://laravel.com/">
                                        <StaticImage width={200} height={200} className="main-skill" src="../img/laravel.png" alt="Laravel" />
                                    </a>
                                </div>
                                <div className="col-3 mx-3 main-fade-in-animation-delay-5">
                                    <a aria-label="Node.js" target="_blank" rel="noopener noreferrer" href="https://nodejs.org/en/about/">
                                        <StaticImage width={200} height={200} className="main-skill" src="../img/node.png" alt="Node.js" />
                                    </a>
                                </div>
                                <div className="col-3 mx-3 main-fade-in-animation-delay-6">
                                    <a aria-label="Python" target="_blank" rel="noopener noreferrer" href="https://www.python.org/">
                                        <StaticImage width={200} height={200} className="main-skill" src="../img/python.png" alt="Python" />
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="experience"></div>
            <section className="container-fluid w-full my-5 py-5 ">
                <div className="container">
                    <div className="row justify-content-center mb-3">
                        <div className="col-md-12 ">
                                <h1 className="text-break">experiences<span className="main-color">::after&#123;</span></h1>
                                <p>Gained an incredible amount of experience on all jobs listed below on how to communicate with <b>international clients</b>, work in a team I even got the chance to <b>lead a team of interns</b> and with programming standards overall.</p>
                        </div>
                    </div>
                    <div className="flex align-items-center justify-content-center">
                        <div className="d-flex justify-content-around md-justify-content-between">
                            <div className="col-4 text-left ex-title">
                                Company:
                            </div>
                            <div className="col-4 text-center ex-title">
                                Role:
                            </div>
                            <div className="col-4 text-right ex-title">
                                Duration:
                            </div>
                        </div>
                        <div className="d-flex justify-content-around md-justify-content-between align-items-center mb-5">
                            <div className="col-4">
                                <StaticImage width={80} height={80} className="main-badge" src="../img/starlabs.png" alt="StarLabs" />
                            </div>
                            <div className="col-4 text-center ex-text">
                                Full Stack Web Developer
                            </div>
                            <div className="col-4 text-right ex-text">
                                Present<br />–<br /> Jun 2019
                            </div>
                        </div>
                        <div className="d-flex justify-content-around md-justify-content-between align-items-center">
                            <div className="col-4">
                                <StaticImage width={80} height={80} className="main-badge" src="../img/zombie.png" alt="Zombie Soup" />
                            </div>
                            <div className="col-4 text-center ex-text">
                                Junior Game Developer
                            </div>
                            <div className="col-4 text-right ex-text">
                                Sep 2018<br />–<br />Jun 2018
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="projects" className="container-fluid my-5 py-5">
                <div className="container">
                    <div className="row justify-content-center mb-3">
                        <div className="col-md-10">
                            <div className="d-inline-block">
                                    <a href="/posts/">
                                        <h1 className="main-reveal-text-short text-wrap">Projects<span className="main-color">::latest()-&gt;get();</span></h1>
                                    </a>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-4">
                        <div className="col-md-10">
                                    <div className="card main-box h-auto h-md-[500px]">
                                        <div className="row align-items-center justify-content-center g-0">
                                            <div className="col-md-5 card-img">
                                                <img src={cardImage}  alt="Logo" />
                                            </div>
                                            <div className="col-md-7">
                                                <div className="card-body">
                                                    <span className="card-code">26 December 2019</span>
                                                    <p className="h5 card-title">Your Vote / Vota Jote</p>
                                                    <div className="card-text">With the power of Web Scrapping we look at the latest articles ...</div>
                                                    <a href="/posts/2021-12-18-your-vote" className="card-button">READ MORE</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="footer-w">
                    <section id="contact" className="container-fluid mb-1">
                        <div className="container h-100">
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center">
                                    <div id="mc_embed_signup">
                                        <form action="https://gmail.us20.list-manage.com/subscribe/post?u=6185038815ef7d21e483057dc&amp;id=79956fe4cf" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="form-inline" target="_blank" novalidate>
                                            <div id="mc_embed_signup_scroll" className="form-group">
                                                <div className="d-inline-block">
                                                        <h1><label htmlFor="mce-EMAIL" className="text-break"><span className="main-color">var_dump(</span>$footer<span className="main-color">)</span></label></h1>
                                                   </div>
                                                <br />
                                                    <div className="d-inline-flex">
                                                        <input type="email" defaultValue="" name="EMAIL" className="form-control" id="mce-EMAIL" placeholder="hello@example.com" required />
                                                        <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_602886b7ffa91c629bc8d12a5_3774b743fb" tabindex="-1" value="" /></div>
                                                        <input type="submit" value=".register()" name="subscribe" id="mc-embedded-subscribe" className="btn main-btn-primary ms-2" />
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <small><em>Ill keep you updated with my random projects/thoughts.</em></small>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
            </div>
        </>
    )
}